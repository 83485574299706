const programData2 = [
  {
    title: "Finalizing College List",
    content: [
      "Help refine your college choices based on thorough research.",
      "Adapt your list to your evolving preferences."
    ],
    image: "https://via.placeholder.com/80"
  },
  {
    title: "Application Completion",
    content: [
      "Guide in filling out applications accurately and effectively.",
      "Highlight your unique qualities and achievements.",
      "Ensure all deadlines and requirements are met."
    ],
    image: "https://via.placeholder.com/80"
  },
  {
    title: "Interview Preparation",
    content: [
      "Conduct mock interviews with feedback.",
      "Provide guidance on common questions and appropriate responses."
    ],
    image: "https://via.placeholder.com/80"
  },
  {
    title: "Supplementary Portfolio Development",
    content: [
      "Guide on creating impactful portfolios for arts, athletics, research, or other areas.",
      "Advise on selecting and presenting your best work.",
      "Ensure your portfolio aligns with and enhances your overall application narrative."
    ],
    image: "https://via.placeholder.com/80"
  }
];

const c2 = "We offer comprehensive support throughout the application process, from finalizing your college list to interview preparation and supplementary portfolio development, ensuring your application stands out."
const title2 = "Application Services: Maximizing Your Admissions Potential"
const head2 = "Enhancing Your Admissions Prospects to the Fullest"

export {programData2, c2, title2, head2};
