const programData3 = [
  {
    title: "Topic Selection",
    content: [
      "Guide in choosing essay topics that highlight personal growth.",
      "Ensure topics complement other aspects of your application.",
      "Make sure topics resonate with admissions committees."
    ],
    image: "https://via.placeholder.com/80"
  },
  {
    title: "Writing Process Support",
    content: [
      "Provide structural and stylistic advice.",
      "Give feedback on drafts.",
      "Guide on maintaining authenticity in your writing."
    ],
    image: "https://via.placeholder.com/80"
  },
  {
    title: "Editing and Refinement",
    content: [
      "Offer detailed feedback to ensure essays are clear and concise.",
      "Check for errors and ensure essays are impactful and memorable."
    ],
    image: "https://via.placeholder.com/80"
  }
];

const c3 = "Our essay support helps you create standout essays that reflect your unique voice. We guide you through topic selection, writing process, and provide detailed feedback to make your essays impactful."
const title3 = "Essay Services: Your Story, Your Way"
const head3 = "Helping You Share Your Story in Your Own Words"

export {programData3, c3, title3, head3};
