const mentors = [
     {
          id: "1",
          email: "satyammehta@berkeley.edu",
          name: "Satyam Mehta",
          phone: "+1 5109907475",
          uni: "UC Berkeley",
          linkedin: "https://www.linkedin.com/in/satyammehta/",
          bio: "My name is Satyam Mehta, and I am a current sophomore at UC Berkeley studying Electrical Engineering and Computer Sciences. My areas of interest include software development, machine learning and AI. My hobbies are playing golf, tennis and chess. I am really looking forward to helping the students at Global Pathfinders for not only getting into their dream college, but also grow as a person!",
          img: "/images/mentors/sm.jpeg"
     },
     {
          id: "2",
          email: "raghavsharma.env@gmail.com",
          name: "Raghav Sharma",
          phone: "+1-6504079951",
          uni: "Stanford University",
          linkedin: "https://www.linkedin.com/in/raghavsharma99/",
          bio: "Graduate Environmental Engineer with focus on Environmental Data, Statistics, and Modeling. Currently working as an Engineer at Berkshire Hathaway performing risk evaluation of flood insurance accounts and developing automation scripts and tools for accurate quantification of catastrophe risks. \n",
          img: "/images/mentors/rs.jpeg"
     },
     {
          id: "3",
          email: "bvnmadhav@gmail.com",
          name: "Madhav Sharma",
          phone: "+1 2365130464",
          uni: "University of British Columbia",
          linkedin: "https://www.linkedin.com/in/sharmamadhav",
          bio: "Hey guys!\nI am Madhav Sharma, a 3rd year Marketing +Business Analytics student at the Sauder School of Business, UBC. I’m an avid chess player, I take at least one coffee to get through the day, and I am always looking to guide students in their college journeys! So feel free to hit me up with any questions, concerns, or even for a chat!",
          img: "/images/mentors/ms.jpeg"
     },
     {
          id: "4",
          email: "naman@breerhk.com",
          name: "Naman Tekriwal",
          phone: "+85266455336",
          uni: "The Hong Kong University of Science and Technology",
          linkedin: "https://www.linkedin.com/in/naman-tekriwal-553758185/",
          bio: "Naman Tekriwal graduated from the Hong Kong University of Science and Technology, pursuing a degree in Management & Marketing. An enthusiastic, highly motivated & dynamic 22-year old, Naman is an impact entrepreneur, currently working on building Breer in Hong Kong, a food up-cycling startup converting surplus bread into craft beer. He actively works in the startup space, advising young startups. He has previously built Rural Invest, a social-fintech company and Young Tycoons Business Challenge, one of the world’s largest business competitions for high school students.",
          img: "/images/mentors/nt.PNG"
     },
     {
          id: "5",
          email: "adlakhaaarushi73@gmail.com",
          name: "Aarushi Adlakha ",
          phone: "+ 1 2019161886",
          uni: "Princeton University ",
          linkedin: "https://www.linkedin.com/in/aarushi-adlakha-566102220/",
          bio: "Hi everyone!\nI am a Psychology Major at Princeton University and am interested in Organizational Behavior and Social Psychology. At Princeton, I am also taking classes in diverse departments like Language and Arts, Near Eastern Studies and Anthropology. Outside of academics I enjoy reading, listening to music, and doing crochet. So excited to connect with you all!",
          img: "/images/mentors/aa.jpeg"
     },
     {
          id: "6",
          email: "d.agrawal3@lse.ac.uk",
          name: "Divyansh Agrawal",
          phone: "+447394481277",
          uni: "London School of Economics and Political Science (LSE)",
          linkedin: "https://www.linkedin.com/in/divyansh22",
          bio: "I'm Divyansh, a proactive individual with a zeal for financial markets and an entrepreneurial slant. Always looking forward to passing down the torch of innovation to the high school community.",
          img: "/images/mentors/da.jpg"
     },
     {
          id: "7",
          email: "agrawalgauri282@gmail.com",
          name: "Gauri Agarwal",
          phone: "(+1)6154980230",
          uni: "Vanderbilt University",
          linkedin: "https://www.linkedin.com/in/gauri-agarwal-0328b3171/",
          bio: "Hi, I’m Gauri! I’m currently majoring in psychology on the pre med track and transitioning to my first semester! If you have any questions do let me know! I’m a gap year student as well and involved in South Asian Society and Kpop dance club at Vandy. ",
          img: "/images/mentors/ga.JPG"
     }
];

export default mentors;
