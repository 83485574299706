const programData5 = [
  {
    title: "Academic-Personal Balance",
    content: [
      "Help develop strategies to manage studies, extracurriculars, and personal time.",
      "Promote both achievement and well-being."
    ],
    image: "https://via.placeholder.com/80"
  },
  {
    title: "Time Management Mastery",
    content: [
      "Teach practical techniques to prioritize tasks.",
      "Set realistic goals and maximize productivity without sacrificing well-being."
    ],
    image: "https://via.placeholder.com/80"
  },
  {
    title: "Interest Exploration and Cultivation",
    content: [
      "Guide in identifying and developing genuine interests.",
      "Help build a profile that's both authentic and compelling."
    ],
    image: "https://via.placeholder.com/80"
  },
  {
    title: "Long-Term Planning",
    content: [
      "Assist in setting meaningful long-term goals.",
      "Map out practical steps to achieve them, aligning with broader life aspirations."
    ],
    image: "https://via.placeholder.com/80"
  }
];

const c5 = "We help you balance academics and personal life, develop time management skills, explore genuine interests, set long-term goals, manage stress, and build leadership abilities for a successful college experience."
const title5 = "Holistic Harmony: Nurturing the Whole You"
const head5 = "Supporting Your Holistic Growth and Well-being"

export {programData5, c5, title5, head5};
