const programData4 = [
  {
    title: "Comparative Analysis",
    content: [
      "Help evaluate your options considering academic programs and campus culture.",
      "Consider location, extracurricular opportunities, and financial aspects."
    ],
    image: "https://via.placeholder.com/80"
  },
  {
    title: "Financial Aid Review",
    content: [
      "Assist in comparing financial aid packages.",
      "Help understand terms and assess long-term implications of different offers."
    ],
    image: "https://via.placeholder.com/80"
  },
  {
    title: "Campus Visit Guidance",
    content: [
      "Provide advice on making the most of accepted students' events.",
      "Guide on final campus visits to inform your decision."
    ],
    image: "https://via.placeholder.com/80"
  },
  {
    title: "Summer Planning",
    content: [
      "Help you make the most of your pre-college summer.",
      "Ensure you're aware of the various support services available on your chosen campus."
    ],
    image: "https://via.placeholder.com/80"
  }
];

const c4 = "After receiving college acceptances, we provide comprehensive support to help you navigate the crucial decision-making process. Our team assists with reviewing financial aid packages, comparing the pros and cons of each institution, and preparing you for the college journey ahead. We ensure you make an informed and confident choice, equipping you with all the necessary resources and guidance to be well-prepared for your future academic endeavors. Our goal is to make this transition as smooth and successful as possible, setting you up for a fulfilling and rewarding college experience."
const title4 = "Post-Acceptance Guidance: Making the Right Choice"
const head4 = "How we help you make the right choice?"

export {programData4, c4, title4, head4};
