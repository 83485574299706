const programData1 = [
  {
    title: "Academic Rigor",
    content: [
      "Guide you in selecting challenging courses that align with your strengths.",
      "Provide strategies to excel in advanced classes.",
      "Connect you with resources for additional support when needed."
    ],
    image: "https://via.placeholder.com/80"
  },
  {
    title: "Test Preparation Guidance",
    content: [
      "Help decide which tests (SAT, ACT, AP, English Proficiency) are beneficial.",
      "Connect you with experienced, high-quality tutors.",
      "Monitor practice test scores and provide ongoing advice.",
      "Plan testing timelines considering application deadlines and potential retakes."
    ],
    image: "https://via.placeholder.com/80"
  },
  {
    title: "Extracurricular Development",
    content: [
      "Help identify and cultivate meaningful activities.",
      "Guide in taking on leadership roles and initiating projects.",
      "Advise on demonstrating commitment and growth in chosen activities."
    ],
    image: "https://via.placeholder.com/80"
  },
  {
    title: "College Research and Best Fit Selection",
    content: [
      "Conduct an in-depth analysis of college programs.",
      "Consider factors like campus culture, location, and extracurricular opportunities.",
      "Help build a balanced list of reach, target, and safety schools.",
      "Provide insights into admission requirements and trends."
    ],
    image: "https://via.placeholder.com/80"
  }
];

const c1 = "We help identify and nurture your unique strengths, transforming them into powerful assets for your college application. This includes academic rigor, test preparation guidance, and extracurricular development."
const title1 = "Profile Building: Crafting Your Unique Masterpiece"
const head1 = "Guiding You in Crafting Your Unique Masterpiece"

export {programData1, c1, title1, head1};
